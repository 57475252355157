import { IMenuOption } from '../models';

export enum FILE_STATUS {
  CREATED = 0,
  PENDING,
  PROCESSED,
  DELETED
}

export class AppConstants {
  //static API_URL: string = 'http://localhost:55577/';
  //static UI_URL: string = 'http://localhost:4210/';
  //static API_URL: string = 'https://clvsbalancereportapitest.clavisco.com/';
  //static API_URL: string = 'https://clvsbalancereportapi.clavisco.com/';
  // Nacascolo
  //static API_URL: string = 'https://nabrpapitest.clavisco.com/';
  //static API_URL: string = 'https://nabrpapi.clavisco.com/';
  //TEST GrupoVargas
  // static API_URL: string = 'https://gvestadoscuentaapitest.clavisco.com/';
  //static API_URL: string = 'https://gvestadoscuentaapi.clavisco.com/';
  //PROD GrupoVargas
  // static API_URL: string = 'https://gvacbapi.clavisco.com/';
  // static UI_URL: string = 'https://gvacb.clavisco.com/';
  //TEST Panaplast
  static API_URL: string = `https://pabalancereportsapitest.clavisco.com/`
  static UI_URL: string = `https://pabalancereportstest.clavisco.com/`
  //PROD Panaplast
  // static API_URL: string = `pabalancereportsapi.clavisco.com`
  // UI_URL: string = `pabalancereports.clavisco.com`
  //TEST GT
  //static API_URL: string = 'https://gtacbapitest.clavisco.com/'
  //UI_URL: string = 'https://gtacbtest.clavisco.com/'
  //PROD GT
  // static API_URL: string = 'https://gtacbapi.clavisco.com/'
  // UI_URL: string = 'https://gtacb.clavisco.com/'

  public static get GetMenu(): IMenuOption[] { return this.MENU };

  public static GetPermissionType(_type: number): string {
    let type = '';

    switch (_type) {
      case 0: {
        type = 'Lectura';
        break;
      }
      case 1: {
        type = 'Modificación';
        break;
      }
    }

    return type;
  }

  private static MENU = [
    {
      Name: 'Inicio',
      Route: '/home',
      Icon: 'fa fa-home',
      Children: [],
      Selected: true,
      Type: 1,
      RequiredPerm: 'V_Home',
      Visible: true
    },
    ///////////////MATERIALES////////////////
    // {
    //   Name: 'Listas materiales - Allix',
    //   Route: '',
    //   Icon: 'fa fa-list-ol title parent-icon',
    //   Children: [
    //     //   {
    //     //   Name: 'Carga de archivos',
    //     //   Route: '/uploads',
    //     //   Icon: 'fa fa-upload subtitle',
    //     //   Children: [],
    //     //   Selected: false,
    //     //   Type: 2,
    //     //   RequiredPerm: 'Estados de cuenta',
    //     //   Visible: true
    //     // },
    //     {
    //       Name: 'Archivos',
    //       Route: '/file-manager',
    //       Icon: 'fa-file-text',
    //       Children: [],
    //       Selected: false,
    //       Type: 2,
    //       RequiredPerm: 'V_AccArc',
    //       Visible: true
    //     },
    //     {
    //       Name: 'Bitácora',
    //       Route: '/binnacle',
    //       Icon: 'fa-shopping-cart',
    //       Children: [],
    //       Selected: false,
    //       Type: 2,
    //       RequiredPerm: 'V_Acc_Bit',
    //       Visible: true
    //     }
    //   ],
    //   Selected: false,
    //   Type: 1,
    //   RequiredPerm: '',
    //   Visible: false
    // },
    ///////////////MENU INTERESES////////////////
    {
      Name: 'Estados de cuenta',
      Route: '',
      Icon: 'fa fa-calculator title parent-icon',
      Children: [{
        Name: 'Buscar',
        Route: '/null',
        Icon: 'fa fa-search subtitle',
        Children: [
          {
            Name: 'Cliente',
            Route: '/search_bplog',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccSeaCli',
            Visible: true
          },
          {
            Name: 'Compañía',
            Route: '/search_companylog',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccSeaCom',
            Visible: true
          },
          {
            Name: 'Proveedores',
            Route: '/search_supplierlog',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccSeaPro',
            Visible: true
          }
        ],
        Selected: false,
        Type: 2,
        RequiredPerm: 'Estados de cuenta',
        Visible: true
      },
      {
        Name: 'Preferencias',
        Route: '/null',
        Icon: 'fa-file-text',
        Children: [
          {
            Name: 'Alertas',
            Route: '/configint_alert',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccAler',
            Visible: true
          },
          {
            Name: 'Correos de alertas',
            Route: '/configint_emailalert',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_EmailAler',
            Visible: true
          },
          {
            Name: 'Compañías',
            Route: '/configint_company',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccAlerCom',
            Visible: true
          },
          {
            Name: 'Alerta por compañía',
            Route: '/configint_assignalert',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccAlerPerCom',
            Visible: true
          },
          {
            Name: 'Gestor',
            Route: '/configint_manager',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccMana',
            Visible: true
          },
          {
            Name: 'Gestor por compañía',
            Route: '/configint_managerbycompany',
            Icon: 'fa-shopping-cart',
            Children: [],
            Selected: false,
            Type: 2,
            RequiredPerm: 'V_AccManaByCompany',
            Visible: true
          }
        ],
        Selected: false,
        Type: 2,
        RequiredPerm: 'Ofertas de veta',
        Visible: true
      }],
      Selected: false,
      Type: 1,
      RequiredPerm: '',
      Visible: true
    },
    ///////////////PREFERENCIALES GENERALES////////////////
    {
      Name: 'Configuraciones',
      Route: '',
      Icon: 'fa fa-sliders fa-lg title parent-icon',
      Children: [
        {
          Name: 'Usuarios',
          Route: '/users',
          Icon: 'fa fa-cogs subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'V_Acc_Usu',
          Visible: true
        },
        {
          Name: 'Asignaciones',
          Route: '/user-assigns',
          Icon: 'fa fa-key subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'V_AccAss',
          Visible: true
        },
      ],
      Selected: false,
      Type: 1,
      RequiredPerm: '',
      Visible: true
    },
    {
      Name: 'Salir',
      Route: '/logout',
      Icon: 'fa fa-sign-out fa-lg title parent-icon',
      Children: [],
      Selected: false,
      Type: 1,
      RequiredPerm: '',
      Visible: true
    },
  ];

}

export const Menu = [
  {
    Name: 'Inicio',
    Path: 'home/',
    Icon: 'fa fa-home',
    Selected: true,
  },
  // {
  //   Name: 'Carga de archivos',
  //   Path: 'uploads',
  //   Icon: 'fa fa-upload',
  //   Selected: false,
  //   RequiredKey: '2',
  // },
  {
    Name: 'Archivos',
    Path: 'file-manager',
    Icon: 'fa fa-files-o',
    Selected: false,
    RequiredKey: '2',
  },
  {
    Name: 'Bitácora',
    Path: 'binnacle',
    Icon: 'fa fa-table',
    Selected: false,
    RequiredKey: '2',
  },
  {
    Name: 'Administración',
    Path: '',
    Icon: 'fa fa-cog',
    Selected: false,
    RequiredKey: '4',
    Children: [
      {
        Name: 'Usuarios',
        Path: 'users',
        Icon: 'fa fa-check',
        Selected: false,
        RequiredKey: '10',
      },
      {
        Name: 'Asignaciones',
        Path: 'user-assigns',
        Icon: '',
        Selected: false,
        RequiredKey: '12',
      }
    ],
    Action: {},
  },
  {
    Name: 'Salir',
    Path: 'login',
    Icon: 'fa fa-sign-out',
    Selected: false,
  },
];

//#region Intereses
export const WeekDays = [
  { id: 1, name: 'Do', checked: false },
  { id: 2, name: 'Lu', checked: false },
  { id: 3, name: 'Ma', checked: false },
  { id: 4, name: 'Mi', checked: false },
  { id: 5, name: 'Ju', checked: false },
  { id: 6, name: 'Vi', checked: false },
  { id: 7, name: 'Sa', checked: false },
]

export const statusLog = [
  { key: '0', value: 'Todos' },
  { key: '1', value: 'Pendiente' },
  { key: '2', value: 'Enviando' },
  { key: '3', value: 'Enviado' },
  { key: '4', value: 'Error' }
];

export enum sendTypes {
  SinSeleccion = '',
  RangoPorFecha = '1',
  Todos = '2',
  PorProveedor = '3'
};

export enum ReportType {
  Company = 1,
  BusinessPartner = 2
}

export enum ReportSubType {
  Company = 1,
  BusinessPartner = 2,
  BusinessPartnerInternal = 3,
  BusinessPartnerSupplier = 4
}
//#endregion
